import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import parse from 'html-react-parser';
import "./style.css";

const PrivacyPolicy = () => {
  const currentLocation = useSelector((state: RootState) => state.location.currentLocation);

  return (
    <div className="privacy-policy-block">
      <h2 className="privacy-policy-block__header">Privacy Policy</h2>
      {parse(currentLocation.privacyPolicy)}
    </div>
  );
};

export default PrivacyPolicy;
