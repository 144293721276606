import "./style.css";
import UsefulLinksItem from "./UsefulLinksItem";
import PhoneIcon from "./images/get-in-touch/phone.icon.svg";
import LocationIcon from "./images/get-in-touch/location.icon.svg";
import Button from "../Button";
import { open } from "../../redux/features/modalSlice";
import { setCurrentLocation } from "../../redux/features/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import { LocationsType } from "../types/getInTouch";
import { locationData, usefulLinksList } from "./data";
import { RootState } from "../../redux/store";

import MnpLogo from "./images/partners/mnp.logo.svg";
import AmlShopLogo from "./images/partners/aml-shop.logo.svg";
import FaskenLogo from "./images/partners/fasken.logo.svg";
import FintracCanafeLogo from "./images/partners/fintrac-canafe.logo.svg";

import ProvenanceLogo from "./images/partners/provenance.logo.svg";
import DentonsLogo from "./images/partners/dentons.logo.svg";
import RSMLogo from "./images/partners/rsm.logo.png";
import CIMALogo from "./images/partners/cima.logo.svg";

import AppleByLogo from "./images/partners/appleby.logo.svg";
import DLTSolutionsLogo from "./images/partners/dlt-solutions.logo.jpg";

import KycAmlPolicyIcon from "./images/useful-links/kyc-aml-policy.icon.svg";
import PrivacyPolicyIcon from "./images/useful-links/privacy-policy.icon.svg";
import TermsConditionsIcon from "./images/useful-links/terms-conditions.icon.svg";
import { useEffect, useMemo } from "react";

const FindUs = () => {
  const preloadSrcList: string[] = useMemo(
    () => [
      MnpLogo,
      AmlShopLogo,
      FaskenLogo,
      FintracCanafeLogo,
      ProvenanceLogo,
      DentonsLogo,
      RSMLogo,
      CIMALogo,
      AppleByLogo,
      DLTSolutionsLogo,
      KycAmlPolicyIcon,
      PrivacyPolicyIcon,
      TermsConditionsIcon,
    ],
    []
  );

  function preloadImage(src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
    });
  }

  useEffect(() => {
    async function effect() {
      const imagesPromiseList: Promise<any>[] = [];
      for (const i of preloadSrcList) {
        imagesPromiseList.push(preloadImage(i));
      }

      await Promise.all(imagesPromiseList);
    }

    effect();
  }, [preloadSrcList]);

  const currentLocation = useSelector(
    (state: RootState) => state.location.currentLocation
  );
  const dispatch = useDispatch();

  const activatePinLocationFn = (location: LocationsType) => {
    const locationIndex = locationData.findIndex(
      (a) => a.location === location
    );
    for (let i = 0; i < locationData.length; i++) {
      if (locationIndex === i) {
        locationData[locationIndex] = {
          ...locationData[locationIndex],
          isActive: true,
        };
      } else {
        locationData[i] = { ...locationData[i], isActive: false };
      }
    }

    dispatch(setCurrentLocation(locationData[locationIndex]));
  };
  return (
    <section className="find-us-block">
      <div className="find-us-block__map">
        <div className="find-us-block__map-bg">
          <div
            className={`find-us-block__map-pin find-us-block__map-pin--canada${
              currentLocation.location === "canada" && currentLocation.isActive
                ? " find-us-block__map-pin--active"
                : ""
            }`}
          >
            <button
              aria-label="pin"
              type="button"
              className="find-us-block__map-pin-btn find-us-block__map-pin-btn"
              onClick={() => activatePinLocationFn("canada")}
            >
              <span className="icon icon-pin">
                <span className="find-us-block__map-pin-btn-location">
                  <span className="find-us-block__map-pin-btn-location-text">
                    Canada
                  </span>
                </span>
              </span>
              <div className="ripple">
                <div className="ripple-1"></div>
                <div className="ripple-2"></div>
                <div className="ripple-3"></div>
              </div>
            </button>
          </div>
          <div
            className={`find-us-block__map-pin find-us-block__map-pin--cayman-islands${
              currentLocation.location === "cayman-islands" &&
              currentLocation.isActive
                ? " find-us-block__map-pin--active"
                : ""
            }`}
          >
            <button
              aria-label="pin"
              type="button"
              className="find-us-block__map-pin-btn find-us-block__map-pin-btn"
              onClick={() => activatePinLocationFn("cayman-islands")}
            >
              <span className="icon icon-pin">
                <span className="find-us-block__map-pin-btn-location">
                  <span className="find-us-block__map-pin-btn-location-text">
                    Cayman
                    <br />
                    Islands
                  </span>
                </span>
              </span>
              <div className="ripple">
                <div className="ripple-1"></div>
                <div className="ripple-2"></div>
                <div className="ripple-3"></div>
              </div>
            </button>
          </div>
          <div
            className={`find-us-block__map-pin find-us-block__map-pin--british-virgin-islands${
              currentLocation.location === "british-virgin-islands" &&
              currentLocation.isActive
                ? " find-us-block__map-pin--active"
                : ""
            }`}
          >
            <button
              aria-label="pin"
              type="button"
              className="find-us-block__map-pin-btn find-us-block__map-pin-btn"
              onClick={() => activatePinLocationFn("british-virgin-islands")}
            >
              <span className="icon icon-pin">
                <span className="find-us-block__map-pin-btn-location">
                  <span className="find-us-block__map-pin-btn-location-text">
                    <span>British Virgin</span>
                    <br />
                    Islands
                  </span>
                </span>
              </span>
              <div className="ripple">
                <div className="ripple-1"></div>
                <div className="ripple-2"></div>
                <div className="ripple-3"></div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className={`container container--${currentLocation.location}`}>
        <div className="row content-row scroll-animate scroll-animate__left-origin">
          <div className="find-us-block__intro">
            <div className="find-us-block__large-text">Where to</div>
            <h2 className="find-us-block__heading">find us</h2>
            <p className="find-us-block__description">
              Select Jurisdiction to see details of our offices and partners.
            </p>
          </div>
        </div>
        <div className="row content-row scroll-animate scroll-animate__left-origin">
          <h3 className="find-us-block__partners-heading">Our Partners</h3>
          <div className="find-us-block__partners-logos-wrap">
            <div className="find-us-block__partners-logos">
              {currentLocation.ourPartners.map((item, key) => (
                <div className="find-us-block__partners-logos-item" key={key}>
                  <img src={item.logo} alt={item.alt} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row content-row scroll-animate scroll-animate__left-origin">
          <h3 className="find-us-block__useful-links-heading">Useful links</h3>
          <ul className="find-us-block__useful-links-list">
            {usefulLinksList.map((item, key) => (
              <li key={key}>
                <UsefulLinksItem {...item} />
              </li>
            ))}
          </ul>
        </div>
        <div className="row content-row scroll-animate scroll-animate__left-origin">
          <h3 className="find-us-block__get-in-touch-heading">Get in touch</h3>
          {currentLocation.getInTouch.registrationInfo && (
            <div className="find-us-block__get-in-touch-sub-text">
              {currentLocation.getInTouch.registrationInfo}
            </div>
          )}
          <ul className="find-us-block__get-in-touch-list">
            <li>
              <div className="find-us-block__get-in-touch-item find-us-block__get-in-touch-item--phone">
                <img src={PhoneIcon} alt="phone icon" />
                <span>{currentLocation.getInTouch.phone}</span>
              </div>
            </li>
            <li>
              <div className="find-us-block__get-in-touch-item find-us-block__get-in-touch-item--address">
                <img src={LocationIcon} alt="location icon" />
                <span>{currentLocation.getInTouch.address}</span>
              </div>
            </li>
          </ul>
          <Button
            className="find-us-block__get-in-touch-contact-us-btn"
            onClick={() => dispatch(open("contact-form"))}
          >
            Contact Us
          </Button>
        </div>
      </div>
    </section>
  );
};

export default FindUs;
