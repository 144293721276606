import { LocationDataType } from "../types/getInTouch";

import MnpLogo from "./images/partners/mnp.logo.svg";
import AmlShopLogo from "./images/partners/aml-shop.logo.svg";
import FaskenLogo from "./images/partners/fasken.logo.svg";
import FintracCanafeLogo from "./images/partners/fintrac-canafe.logo.svg";

import ProvenanceLogo from "./images/partners/provenance.logo.svg";
import DentonsLogo from "./images/partners/dentons.logo.svg";
import RSMLogo from "./images/partners/rsm.logo.png";
import CIMALogo from "./images/partners/cima.logo.svg";

import AppleByLogo from "./images/partners/appleby.logo.svg";
import DLTSolutionsLogo from "./images/partners/dlt-solutions.logo.jpg";

import KycAmlPolicyIcon from "./images/useful-links/kyc-aml-policy.icon.svg";
import PrivacyPolicyIcon from "./images/useful-links/privacy-policy.icon.svg";
import TermsConditionsIcon from "./images/useful-links/terms-conditions.icon.svg";

export const usefulLinksList = [
  {
    icon: KycAmlPolicyIcon,
    title: "KYC & AML Policy",
    modal: "kyc-aml-policy",
  },
  {
    icon: PrivacyPolicyIcon,
    title: "Privacy Policy",
    modal: "privacy-policy",
  },
  {
    icon: TermsConditionsIcon,
    title: "Terms & Conditions",
    modal: "terms-of-use",
  },
];

export const locationData: LocationDataType[] = [
  {
    location: "canada",
    ourPartners: [
      {
        logo: MnpLogo,
        alt: "MNP Logo",
      },
      {
        logo: AmlShopLogo,
        alt: "The AML Shop Logo",
      },
      {
        logo: FaskenLogo,
        alt: "Fasken Logo",
      },
      {
        logo: FintracCanafeLogo,
        alt: "Fintrac Canafe Logo",
      },
    ],
    getInTouch: {
      registrationInfo: "RYKI INC. | FINTRAC MSB Registration #M19525430",
      phone: "(226) 240-7189",
      address: "139 Northfield Drive W. Suite 3. Waterloo, ON, Canada, N2L 5A6",
    },
    footerContent: `RYKI INC.<br />
    (226) 240-7189<br/>
    139 Northfield Drive W. Suite 3<br/>
    Waterloo, ON, Canada, N2L 5A6<br/>
    FINTRAC MSB Registration #M19525430`,
    copyright: `© 2023. For information, contact Ryki Inc.`,
    kycAndAml: `<p>
      RYKI is committed to preventing fraud, money laundering and terrorist
      financing, as well as to complying with relevant legislation. As such,
      RYKI is registered as a money services business (MSB) with the Financial
      Transactions Reporting and Analysis Centre (FINTRAC) and the Autorité
      des marchés financiers (AMF) in Quebec and is subject to the Proceeds of
      Crime (Money Laundering) and Terrorist Financing Act and the Quebec
      Money Services Business Act (collectively, the Legislation).
    </p>
    <p>
      The Legislation requires RYKI to collect certain information about its
      clients and report certain transactions to FINTRAC or law enforcement
      agencies as well as to develop a comprehensive compliance program that
      consists of:
    </p>
    <ul>
      <li>documented policies and procedures</li>
      <li>
        the designation of an individual responsible for the design and
        oversight of the compliance program
      </li>
      <li>
        an assessment of RYKI\`'\`s exposure to money laundering and terrorist
        financing risks
      </li>
      <li>
        the provision of ongoing training to members of our organization
      </li>
      <li>periodic reviews of the overall effectiveness of the program.</li>
    </ul>`,
    privacyPolicy: `<h3>Overview</h3>
    <p>
      Ryki Inc. (“ Ryki ”) is committed to helping protect the privacy and
      security of your personal information obtained by reason of your
      customer relationship with Ryki. Personal information is information
      about an identifiable individual, such as your name, address, telephone
      number, or email address (“ Personal Information ”). This privacy policy
      (“ Privacy Policy ”) summarizes what Personal Information Ryki collects,
      and how we use and disclose this information in providing you with
      trading services (the “ Services ”) and making Ryki.io available to you.
      This Privacy Policy also describes other important topics relating to
      your privacy. By using or accessing the Services, by visiting Ryki.io,
      or by otherwise choosing to submit Personal Information to Ryki, you
      signify your consent to all of the terms of this Privacy Policy. If you
      do not agree with any terms of this Privacy Policy, you may not use the
      Services or submit any Personal Information to Ryki. Ryki reserves the
      right to modify this Privacy Policy at any time. We will reflect any
      such modifications to this Privacy Policy on this pageand will indicate
      the date on which it was last amended. Your continued use of the
      Services or access to Ryki.io after any such changes constitutes your
      acceptance of, and agreement to, this Privacy Policy, as revised. Please
      periodically review this Privacy Policy so that you know what personal
      information we collect, how we use it, and with whom we may share it.
      This Privacy Policy covers:
    </p>
    <ol>
      <li>
        What Personal Information Ryki Collects.
        <ol>
          <li>
            a. We collect Personal Information when you register to use the
            Services, when we carry out the Services and when you give us
            information. When you open an account, we may collect your full
            name, contact information, proof of address (such as a hydro
            bill), birthdate, occupation, signature, and government ID (such
            as driver’s license, passport, or permanent resident card). If you
            represent a corporation, you will be asked to provide information
            about the company, and personal information of its beneficial
            owners and authorized signatories. You must have their permission
            to do so.
          </li>
          <li>
            When you use the Services, we collect contact information, the
            purpose, beneficiary, recipient and amount of a trade, the public
            key for your wallet, information on your bank account and
            institution needed for the transaction, and any other information
            you provide.
          </li>
          <li>
            When you visit the website, we collect browser information, the
            date and time of your visit, the pages you view, and the services
            you request.
          </li>
        </ol>
      </li>
      <li>
        How Ryki Uses Personal Information. We use information to provide our
        services, and to meet legal obligations. This includes ‘know your
        client’ and anti-money laundering obligations, protecting against
        fraud or errors, responding to questions, improving or developing
        services, communicating with you, and carrying out other purposes you
        agree to.
      </li>
      <li>
        How Ryki Discloses Personal Information. We disclose information to
        provide services you request (for example to an exchange to allow a
        trade), to our service providers, and if we enter a business
        transaction.
      </li>
      <li>
        Your Choices. You can opt-out of our commercial messages, if you
        receive them.
      </li>
      <li>
        How Ryki Protects Personal Information. While we use physical,
        organizational and technological safeguards, no security can provide
        absolute protection. We cannot ensure or warrant the security of any
        information.
      </li>
      <li>
        We may transfer Personal Information Outside of Canada. We transfer
        information to the United States, and other countries. Information
        processed and stored in other countries may be accessed by governments
        and law enforcement according to their laws.
      </li>
      <li>
        Information Retention. We retain information as necessary to fulfill
        the purposes for which it was collected or to meet any legal
        requirements.
      </li>
      <li>
        Your right of Access and Rectification. You can request access to and
        correction of your personal information we hold.
      </li>
      <li>
        Third Party Sites. While this Site may link to third party sites, we
        are not responsible for them.
      </li>
      <li>
        How to Contact Us. You may contact us if you have questions or
        complaints at support@ryki.io.
      </li>
    </ol>`,
    termsAndCondition: `<p>
      PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. Your use of the
      services offered by Ryki Inc. and the content provided by Ryki on or
      through the services (collectively, the “ Platform ”) are expressly
      conditioned on your acceptance without modification of the following
      terms and conditions (the “ Terms and Conditions ”). If you accept these
      Terms and Conditions, click on the “I Accept” button below. That action
      is the equivalent of your signature and indicates your acceptance of
      these Terms and Conditions and that you intend to be legally bound by
      them. Ryki Inc. (“ Ryki ”) may modify, supplement or amend these Terms
      and Conditions at its sole discretion. If so, you will be notified the
      next time you use the Platform, and given an opportunity to accept the
      modifications. Your continued use of the Platform will be evidence that
      you acknowledge and agree to be bound by the modified Terms and
      Conditions. If you do not accept the modifications, you are no longer
      entitled to use the Platform.
    </p>
    <dl className="dd-number-list">
      <dt>Use of the Platform</dt>
      <dd>
        Ryki grants you a limited, non-exclusive, non-transferable and
        personal license to access and use the Platform, provided you have
        created an Account (as defined below) and are in compliance with these
        Terms and Conditions.
      </dd>
      <dd>
        All content included on the Platform is the property of Ryki or its
        content suppliers and protected by Canadian and international
        copyright laws. You agree not to permit access to the Platform to
        others, and not to publish, reproduce, sublicense or modify any
        content appearing on the Platform for any purpose without the prior
        written consent of Ryki.
      </dd>
      <dd>
        You may not use the Platform if you live in a jurisdiction where
        access to or use of the Platform or any part of it may be illegal or
        prohibited. It is solely your responsibility to determine whether your
        use of the Platform is lawful in the jurisdiction in which you reside,
        and you agree to comply with all applicable laws in your use of the
        Platform.
      </dd>
      <dd>
        You shall be responsible for all access and service fees necessary to
        connect to or communicate with the Platform and assume all charges
        incurred in accessing such systems.
      </dd>
      <dd>
        You will implement and maintain appropriate protection in relation to
        the security and control of access to your computer, computer viruses
        or other similar harmful or inappropriate materials, devices,
        information or data. You assume all risks associated with the use and
        storage of information on your personal computer or on any other
        computer through which you will gain access to the Platform.
      </dd>
      <dd>
        In its sole discretion and for any reason, in addition to any other
        rights or remediesavailable to Ryki and without any liability
        whatsoever, Ryki at any time and without notice may terminate or
        restrict your access to any component of the Platform.
      </dd>
      <dt>Platform Account</dt>
      <dd>
        You must create an account in order to access the Platform (your “
        Account ”). When you create an Account, you will be required to
        provide personal information and about your use of the Platform to
        Ryki. Ryki may, in its discretion, accept or reject your application
        for an Account.
      </dd>
      <dd>
        All use, collection, and disclosure of your personal information will
        be governed by Ryki’s Privacy Policy, which is available at
        ryki.io/ca/privacy. You have read Ryki’s Privacy Policy, and you agree
        that the terms of that policy are reasonable. You consent to the
        collection, use and disclosure of your personal information by Ryki in
        accordance with the terms of and for the purposes set out in Ryki’s
        Privacy Policy.
      </dd>
      <dd>
        You agree that Ryki, or a service provider engaged by Ryki, may verify
        your name, address, phone number, and other Account details against
        information about you held by third party sources in order to verify
        your identity to fulfill its anti-money laundering obligations and to
        help protect against fraud or misappropriation of user Accounts.
      </dd>
      <dd>
        You covenant and represent that you will provide truthful, accurate
        and complete information upon opening your Account. If any of the
        information provided by you to Ryki changes, you agree to provide Ryki
        with sufficient details no later than 30 days after such change.
      </dd>
      <dd>
        Your Account may be accessed only by use of your account name and
        password, or if you are accessing the platform in person or by
        telephone, by other identifying information agreed upon between you
        and Ryki from time to time (your “ Credentials ”). You are solely
        responsible and liable for any use and misuse of your Credentials and
        for all activities that occur under your Credentials. For security
        reasons, you must keep your Credentials confidential and not disclose
        them to any person or permit any other person to use them, except with
        the prior written consent of Ryki.
      </dd>
      <dd>
        You must immediately notify Ryki of any unauthorized use of your
        Credentials, or if you know or suspect that your Credentials have been
        lost or stolen, has become known to any other person, or has been
        otherwise compromised. You agree to take full responsibility for all
        use and misuse that occurs under your Account (including all
        transactions using your Account) and for all resulting loss, damage,
        and liability.
      </dd>
      <dd>
        If you allow any third party to access the Platform (including any of
        your Accounts) in a manner unauthorized by Ryki (including, without
        limitation, screen scrapping), you will indemnify Ryki and its
        employees, officers, directors, and agents and hold each of them
        harmless against any and all losses, liability, costs or damages
        arising out of any claim or suit by any such third party based upon or
        relating in any way to such access or use of the Platform.
      </dd>
      <dt>Trading</dt>
      <dd>
        In order to initiate a trade using the Platform, you must first
        either: a. transfer United States Dollars or Canadian Dollars
        (collectively, “ Fiat Currency ”), to a bank account controlled by
        Ryki; or b. transfer bitcoin or ether (collectively, “ Cryptocurrency
        ”) to a digital wallet controlled by Ryki.
      </dd>
      <dd>
        Fiat Currency or Cryptocurrency transferred by you to Ryki
        (hereinafter referred to as “ Customer Funds ”) in accordance with
        section 14 will be credited to your Account and held by Ryki on your
        behalf separately from its operating bank account.
      </dd>
      <dd>
        There will be no interest paid on any Customer Funds held by Ryki for
        your Account.
      </dd>
      <dd>
        Upon receiving written or verbal instructions from you, Ryki may
        faciliate a trade of your Customer Funds for another Fiat Currency or
        Cryptocurrency (a “ Trade ”). All instructions provided by you to Ryki
        are considered final and all Trades are non-refundable and executed on
        a reasonable efforts basis. Ryki is not responsible for any incorrect
        instructions provided by you.
      </dd>
      <dd>
        Any proceeds from a Trade by you will be credited to your Account,
        less any fees due to Ryki for the Trade and any other services Ryki
        may provide.
      </dd>
      <dd>
        Proceeds from each trade will be immediately transferred to your bank
        account via wire transfer or to your digital asset wallet on the
        blockchain. Transfers will occur as soon as commercially viable and
        not extending a period of 5 business days.
      </dd>
      <dd>
        Ryki’s prices are all in. Prices take into account all expenses
        incurred by Ryki and are considered binding for both parties.
      </dd>
      <dt>Risks and Prohibited Conduct</dt>
      <dd>
        You acknowledge that Ryki does not guarantee the accuracy of exchange
        rates and does not guarantee that the Trades facilitated by Ryki will
        be favourable or be based on accurate information. You are solely
        responsible for any trading decision made and accept any loss or
        foregone financial benefit, even if the decision is based on
        information provided by Ryki.
      </dd>
      <dd>
        Ryki does not provide any trading advice or recommendations. No orders
        are reviewed by Ryki for suitability.
      </dd>
      <dd>
        You are responsible for complying with applicable laws in your use of
        the Platform. Do not use the Platform if buying and selling
        Cryptocurrency is not legal in your jurisdiction.
      </dd>
      <dd>
        You agree that Ryki may, in its discretion, determine not to hold
        digital assets that result from hard forks of Cryptocurrencies. In
        this circumstance, if you wish to acquire assets resulting from a hard
        fork, you should withdraw your Customer Funds prior to the hard fork.
      </dd>
      <dd>
        You acknowledge that Cryptocurrencies involve significant risk. These
        risks include, but are not limited to:
        <ol>
          <li>Cryptocurrency prices are volatile;</li>
          <li>digital wallets and exchanges may be hacked;</li>
          <li>
            holders of Cryptocurrencies do not have the same legal rights
            associated with security ownership or deposits with regulated
            financial institutions;
          </li>
          <li>
            it may be difficult to find accurate and current information
            relating to Cryptocurrencies;
          </li>
          <li>Trades may take several days to settle; and</li>
          <li>
            amounts of Fiat Currency and Cryptocurrency held by Ryki on your
            behalf are not protected by any deposit insurance or investment
            protection scheme. You acknowledge that in no event will Ryki be
            responsible for any losses or damage to you as a result of these
            risks.
          </li>
        </ol>
      </dd>
      <dt>Representations and Warranties</dt>
      <dd>
        You represent and warrant that:
        <ol>
          <li>
            If you are an individual, that you are of the age of majority,
            that you have the capacity to enter into and be bound by these
            Terms and Conditions, and that you will use the Platform under
            your own name and for your sole benefit;
          </li>
          <li>
            If you are a legal entity other than an individual, the legal
            entity is duly organized and existing and the individual accepting
            these Terms and Conditions is of the age of majority and has the
            authority to enter into and cause the legal entity to be bound by
            these Terms and Conditions;
          </li>
          <li>
            The information provided by you upon to Ryki upon opening your
            Account is accurate and complete;
          </li>
          <li>
            You are not prohibited or restricted from using the Platform.
          </li>
        </ol>
      </dd>
      <dt>Liability disclaimer – READ THE FOLLOWING CAREFULLY</dt>
      <dd>
        The Platform is provided on an “as is” and “as available” basis. To
        the fullest extent permitted by law, Ryki expressly disclaims all
        representations and warranties, whether express or implied, statutory
        or otherwise, including:
        <ol>
          <li>any warranty of merchantability;</li>
          <li>fitness for a particular purpose;</li>
          <li>non-infringement;</li>
          <li>
            that the Platform or any Cryptocurrency acquired using the
            Platform will meet your requirements; and
          </li>
          <li>
            that your access to the Platform will be uninterrupted or
            error-free.
          </li>
        </ol>
      </dd>
      <dd>
        In no event will Ryki or any of its directors, officers, employees, or
        agents be liable for any injury, loss, claim, damage, or any special,
        exemplary, punitive, direct, indirect, incidental or consequential
        damages of any kind (including, but not limited to economic loss,
        foregone financial benefit or lost savings), whether based in
        contract, tort, strict liability, or otherwise, which arises out of or
        is in any way connected with any use of the Platform or its content,
        or the services offered on the Platform, even if advised of the
        possibility of damages.
      </dd>
      <dd>
        If Ryki should be found liable for any loss or damage which arises out
        of or is in any way connected with any of the functions or uses of the
        Platform or its content, or any of the services offered on the
        Platform, the liability of Ryki will in no event exceed in the
        aggregate the amount paid by you under these Terms and Conditions for
        the specific services to which the loss or damage relates.
      </dd>
      <dd>
        You are solely responsible for any and all tax consequences you may
        incur as a result of your use of the Platform.
      </dd>
      <dt>Indemnification</dt>
      <dd>
        You agree to indemnify and save harmless Ryki, its affiliates and any
        of their respective officers, directors, employees, consultants,
        representatives and agents from and against any claim, cause of action
        or demand, including without limitation reasonable legal, accounting
        and other professional fees, brought as a result of your use of the
        Platform or your breach of these Terms and Conditions.
      </dd>
      <dt>Survival</dt>
      <dd>
        Sections F, G and I will survive the termination of your access to the
        Platform.
      </dd>
      <dt>General</dt>
      <dd>
        These Terms and Conditions may not be assigned by you or otherwise
        transferred in whole or in part without the prior written consent of
        Ryki. Ryki may assign these Terms and Conditions without obtaining
        your consent.
      </dd>
      <dd>
        The relationship between Ryki and you will be that of independent
        contractors, and neither party nor any of its respective officers,
        agents or employees will be held or construed to be partners, joint
        ventures, fiduciaries, employees or agents of the other as a result of
        these Terms and Conditions or your use of the Platform.
      </dd>
      <dd>
        You consent and submit to the exclusive jurisdiction of the courts
        located in the City of Toronto, in the Province of Ontario, Canada, in
        all disputes arising out of or relating to the use of the Platform and
        these Terms and Conditions. This agreement and its performance will be
        governed by the laws of the Province of Ontario, Canada, and the
        federal laws of Canada applicable in that Province.
      </dd>
      <dd>
        These Terms and Conditions will be severable. In the event that any
        provision is determined to be unenforceable or invalid, that provision
        will nonetheless be enforced to the fullest extent permitted by
        applicable law, and that determination will not affect the validity
        and enforceability of any other remaining provisions of these Terms
        and Conditions.
      </dd>
      <dd>
        These Terms and Conditions, together with those incorporated or
        referred to in these Terms and Conditions, constitute the entire
        agreement between you and Ryki pertaining to the subject matter of
        these Terms and Conditions, and supersede any prior agreements,
        understandings, negotiations and discussions, whether electronic, oral
        or written, regarding the subject matter of these Terms and
        Conditions, and may not be amended or modified except by the Company
        as set out above. There are no representations, warranties or other
        agreements between us in connection with the subject matter of these
        Terms and Conditions, except as specifically set out in these Terms
        and Conditions or in those incorporated or referred to in these Terms
        and Conditions. No party has been induced to enter into these Term and
        Conditions in reliance on, and there will be no liability assessed,
        either in tort or contract, with respect to, any warranty,
        representation, opinion, advice or assertion of fact, except to the
        extent it has been reduced to writing and included in these Terms and
        Conditions or in those incorporated or referred to in these Terms and
        Conditions.
      </dd>
      <dd>
        You consent to receive communications from Ryki electronically. You
        agree that all agreements, notices, disclosures and other
        communications that Ryki provides to you electronically satisfy any
        legal requirement that such communications be in writing.
      </dd>
      <dd>
        The legal business name of the Company is Ryki Inc. Please direct
        enquiries to the Company’s registered business address at 139
        Northfield Drive West, Suite 3, Waterloo, Ontario, N2L 5A6, or by
        telephone at (226) 240-7189 or e-mail at admin@ryki.io.
      </dd>
    </dl>`,
    isActive: true,
  },
  {
    location: "cayman-islands",
    ourPartners: [
      {
        logo: ProvenanceLogo,
        alt: "Provenance Logo",
      },
      {
        logo: DentonsLogo,
        alt: "Dentons Logo",
      },
      {
        logo: RSMLogo,
        alt: "RSM Logo",
      },
      {
        logo: CIMALogo,
        alt: "Cayman Island Monetary Authority Logo",
      },
    ],
    getInTouch: {
      registrationInfo: "RYKI Ltd. | CIMA VASP no. 1917020",
      phone: "(226) 240-7189",
      address:
        "DDM Corporate Ltd, 3rd Floor One Capital Place, PO Box 10190 Grand Cayman, Cayman Islands",
    },
    footerContent: `RYKI Ltd.<br />
    (226) 240-7189<br/>
    DDM Corporate Ltd, 3rd Floor One Capital Place,<br/>
    PO Box 10190 Grand Cayman, Cayman Islands<br/>
    CIMA VASP no. 1917020`,
    copyright: `© 2023. For information, contact Ryki Ltd.`,
    kycAndAml: `<p>RYKI is committed to preventing fraud, money laundering and terrorist financing, as well as to complying with relevant legislation. As such, RYKI is registered as a virtual asset service provider (VASP) with the Cayman Islands Monetary Authority (CIMA).</p>
    <br/>
    <p>Regulation requires RYKI to collect certain information about its clients and report certain transactions to law enforcement agencies as well as to develop a comprehensive compliance program that consists of:</p>
    <ul>
      <li>documented policies and procedures</li>
      <li>the designation of an individual responsible for the design and oversight of the compliance program</li>
      <li>an assessment of RYKI’s exposure to money laundering and terrorist financing risks</li>
      <li>the provision of ongoing training to members of our organization</li>
      <li>periodic reviews of the overall effectiveness of the program.</li>
    </ul>`,
    privacyPolicy: `<h3>Overview</h3>
    <p>
      Ryki Ltd. (“ Ryki ”) is committed to helping protect the privacy and
      security of your personal information obtained by reason of your
      customer relationship with Ryki. Personal information is information
      about an identifiable individual, such as your name, address, telephone
      number, or email address (“ Personal Information ”). This privacy policy
      (“ Privacy Policy ”) summarizes what Personal Information Ryki collects,
      and how we use and disclose this information in providing you with
      trading services (the “ Services ”) and making Ryki.io available to you.
      This Privacy Policy also describes other important topics relating to
      your privacy. By using or accessing the Services, by visiting Ryki.io,
      or by otherwise choosing to submit Personal Information to Ryki, you
      signify your consent to all of the terms of this Privacy Policy. If you
      do not agree with any terms of this Privacy Policy, you may not use the
      Services or submit any Personal Information to Ryki. Ryki reserves the
      right to modify this Privacy Policy at any time. We will reflect any
      such modifications to this Privacy Policy on this pageand will indicate
      the date on which it was last amended. Your continued use of the
      Services or access to Ryki.io after any such changes constitutes your
      acceptance of, and agreement to, this Privacy Policy, as revised. Please
      periodically review this Privacy Policy so that you know what personal
      information we collect, how we use it, and with whom we may share it.
      This Privacy Policy covers:
    </p>
    <ol>
      <li>
        What Personal Information Ryki Collects.
        <ol>
          <li>
            a. We collect Personal Information when you register to use the
            Services, when we carry out the Services and when you give us
            information. When you open an account, we may collect your full
            name, contact information, proof of address (such as a hydro
            bill), birthdate, occupation, signature, and government ID (such
            as driver’s license, passport, or permanent resident card). If you
            represent a corporation, you will be asked to provide information
            about the company, and personal information of its beneficial
            owners and authorized signatories. You must have their permission
            to do so.
          </li>
          <li>
            When you use the Services, we collect contact information, the
            purpose, beneficiary, recipient and amount of a trade, the public
            key for your wallet, information on your bank account and
            institution needed for the transaction, and any other information
            you provide.
          </li>
          <li>
            When you visit the website, we collect browser information, the
            date and time of your visit, the pages you view, and the services
            you request.
          </li>
        </ol>
      </li>
      <li>
        How Ryki Uses Personal Information. We use information to provide our
        services, and to meet legal obligations. This includes ‘know your
        client’ and anti-money laundering obligations, protecting against
        fraud or errors, responding to questions, improving or developing
        services, communicating with you, and carrying out other purposes you
        agree to.
      </li>
      <li>
        How Ryki Discloses Personal Information. We disclose information to
        provide services you request (for example to an exchange to allow a
        trade), to our service providers, and if we enter a business
        transaction.
      </li>
      <li>
        Your Choices. You can opt-out of our commercial messages, if you
        receive them.
      </li>
      <li>
        How Ryki Protects Personal Information. While we use physical,
        organizational and technological safeguards, no security can provide
        absolute protection. We cannot ensure or warrant the security of any
        information.
      </li>
      <li>
        We may transfer Personal Information Outside of Cayman Islands. We transfer
        information to the United States, and other countries. Information
        processed and stored in other countries may be accessed by governments
        and law enforcement according to their laws.
      </li>
      <li>
        Information Retention. We retain information as necessary to fulfill
        the purposes for which it was collected or to meet any legal
        requirements.
      </li>
      <li>
        Your right of Access and Rectification. You can request access to and
        correction of your personal information we hold.
      </li>
      <li>
        Third Party Sites. While this Site may link to third party sites, we
        are not responsible for them.
      </li>
      <li>
        How to Contact Us. You may contact us if you have questions or
        complaints at support@ryki.io.
      </li>
    </ol>`,
    termsAndCondition: `<p>PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. Your use
    of the services offered by Ryki Ltd. (and the content provided by
    Ryki Ltd. on or through the services (collectively, the
    “Platform”) are expressly conditioned on your acceptance without
    modification of the following terms and conditions (the “Terms and
    Conditions”). If you accept these Terms and Conditions, click on
    the “I Accept” button below. That action is the equivalent of your
    signature and indicates your acceptance of these Terms and
    Conditions and that you intend to be legally bound by them. Ryki
    Ltd. (“Ryki”) may modify, supplement or amend these Terms and
    Conditions at its sole discretion. If so, you will be notified the
    next time you use the Platform, and given an opportunity to accept
    the modifications. Your continued use of the Platform will be
    evidence that you acknowledge and agree to be bound by the
    modified Terms and Conditions. If you do not accept the
    modifications, you are no longer entitled to use the Platform.</p>
    <dl className="dd-number-list">
      <dt>Use of the Platform</dt>
      <dd>Ryki grants you a limited, non-exclusive, non-transferable and
      personal license to access and use the Platform, provided you
      have created an Account (as defined below) and are in compliance
      with these Terms and Conditions.</dd>
      <dd>All content included on the Platform is the property of Ryki or
      its content suppliers and protected by international copyright
      laws. You agree not to permit access to the Platform to others,
      and not to publish, reproduce, sublicense or modify any content
      appearing on the Platform for any purpose without the prior
      written consent of Ryki.</dd>
      <dd>You may not use the Platform if you live in a jurisdiction where
      access to or use of the Platform or any part of it may be
      illegal or prohibited. It is solely your responsibility to
      determine whether your use of the Platform is lawful in the
      jurisdiction in which you reside, and you agree to comply with
      all applicable laws in your use of the Platform.</dd>
      <dd>You shall be responsible for all access and service fees
      necessary to connect to or communicate with the Platform and
      assume all charges incurred in accessing such systems.</dd>
      <dd>You will implement and maintain appropriate protection in
      relation to the security and control of access to your computer,
      computer viruses or other similar harmful or inappropriate
      materials, devices, information or data. You assume all risks
      associated with the use and storage of information on your
      personal computer or on any other computer through which you
      will gain access to the Platform.</dd>
      <dd>In its sole discretion and for any reason, in addition to any
      other rights or remedies available to Ryki and without any
      liability whatsoever, Ryki at any time and without notice may
      terminate or restrict your access to any component of the
      Platform.</dd>
      <dt>Platform Account</dt>
      <dd>You must create an account in order to access the Platform
      (your “ Account ”). When you create an Account, you will be
      required to provide personal information and about your use of
      the Platform to Ryki. Ryki may, in its discretion, accept or
      reject your application for an Account.</dd>
      <dd>All use, collection, and disclosure of your personal
      information will be governed by Ryki’s Privacy Policy, which
      is available at ryki.io/ky/privacy. You have read Ryki’s
      Privacy Policy, and you agree that the terms of that policy
      are reasonable. You consent to the collection, use and
      disclosure of your personal information by Ryki in accordance
      with the terms of and for the purposes set out in Ryki’s
      Privacy Policy.</dd>
      <dd>You agree that Ryki, or a service provider engaged by Ryki,
      may verify your name, address, phone number, and other Account
      details against information about you held by third party
      sources in order to verify your identity to fulfill its
      anti-money laundering obligations and to help protect against
      fraud or misappropriation of user Accounts.</dd>
      <dd>You covenant and represent that you will provide truthful,
      accurate and complete information upon opening your Account.
      If any of the informationprovided by you to Ryki changes, you
      agree to provide Ryki with sufficient details no later than 30
      days after such change.</dd>
      <dd>Your Account may be accessed only by use of your account name
      and password, or if you are accessing the platform in person
      or by telephone, by other identifying information agreed upon
      between you and Ryki from time to time (your “Credentials”).
      You are solely responsible and liable for any use and misuse
      of your Credentials and for all activities that occur under
      your Credentials. For security reasons, you must keep your
      Credentials confidential and not disclose them to any person
      or permit any other person to use them, except with the prior
      written consent of Ryki.</dd>
      <dd>You must immediately notify Ryki of any unauthorized use of
      your Credentials, or if you know or suspect that your
      Credentials have been lost or stolen, has become known to any
      other person, or has been otherwise compromised. You agree to
      take full responsibility for all use and misuse that occurs
      under your Account (including all transactions using your
      Account) and for all resulting loss, damage, and liability.</dd>
      <dd>If you allow any third party to access the Platform (including
        any of your Accounts) in a manner unauthorized by Ryki
        (including, without limitation, screen scrapping), you will
        indemnify Ryki and its employees, officers, directors, and
        agents and hold each of them harmless against any and all
        losses, liability, costs or damages arising out of any claim
        or suit by any such third party based upon or relating in any
        way to such access or use of the Platform.</dd>
      <dt>Trading</dt>
      <dd>In order to initiate a trade using the Platform, you must
      first either: transfer United States Dollars (collectively,
      “Fiat Currency”), to a bank account controlled by Ryki; or
      transfer bitcoin or ether (collectively, “Cryptocurrency”) to
      a digital wallet controlled by Ryki.</dd>
      <dd>In order to initiate a trade using the Platform, you must
      first either: transfer United States Dollars (collectively,
      “Fiat Currency”), to a bank account controlled by Ryki; or
      transfer bitcoin or ether (collectively, “Cryptocurrency”) to
      a digital wallet controlled by Ryki.</dd>
      <dd>Fiat Currency or Cryptocurrency transferred by you to Ryki
      (hereinafter referred to as “Customer Funds”) in accordance
      with section 14 will be credited to yourAccount and held by
      Ryki on your behalf separately from its operating bank
      account.</dd>
      <dd>There will be no interest paid on any Customer Funds held by
      Ryki for your Account.</dd>
      <dd>Upon receiving written or verbal instructions from you, Ryki
      may faciliate a trade of your Customer Funds for another Fiat
      Currency or Cryptocurrency (a “ Trade ”). All instructions
      provided by you to Ryki are considered final and all Trades
      are non-refundable and executed on a reasonable efforts basis.
      Ryki is not responsible for any incorrect instructions
      provided by you.</dd>
      <dd>Any proceeds from a Trade by you will be credited to your
      Account, less any fees due to Ryki for the Trade and any other
      services Ryki may provide.</dd>
      <dd>Proceeds from each trade will be immediately transferred to
      your bank account via wire transfer or to your digital asset
      wallet on the blockchain. Transfers will occur as soon as
      commercially viable and not extending a period of 5 business
      days.</dd>
      <dd>Ryki’s prices are all in. Prices take into account all
      expenses incurred by Ryki and are considered binding for both
      parties.</dd>
      <dt>Risks and Prohibited Conduct</dt>
      <dd>You acknowledge that Ryki does not guarantee the accuracy of
      exchange rates and does not guarantee that the Trades
      facilitated by Ryki will be favourable or be based on accurate
      information. You are solely responsible for any trading
      decision made and accept any loss or foregone financial
      benefit, even if the decision is based on information provided
      by Ryki.</dd>
      <dd>Ryki does not provide any trading advice or recommendations.
      No orders are reviewed by Ryki for suitability.</dd>
      <dd>You are responsible for complying with applicable laws in your
      use of the Platform. Do not use the Platform if buying and
      selling Cryptocurrency is not legal in your jurisdiction.</dd>
      <dd>You agree that Ryki may, in its discretion, determine not to
      hold digital assets that result from hard forks of
      Cryptocurrencies. In this circumstance, if you wish to acquire
      assets resulting from a hard fork, you should withdraw your
      Customer Funds prior to the hard fork.</dd>
      <dd>You acknowledge that Cryptocurrencies involve significant
      risk. These risks include, but are not limited to:
        <ol>
          <li>Cryptocurrency prices are volatile;</li>
          <li>digital wallets and exchanges may be hacked;</li>
          <li>
            holders of Cryptocurrencies do not have the same legal
            rights associated with security ownership or deposits with
            regulated financial institutions;
          </li>
          <li>
            it may be difficult to find accurate and current
            information relating to Cryptocurrencies;
          </li>
          <li>Trades may take several days to settle; and</li>
          <li>
            amounts of Fiat Currency and Cryptocurrency held by
            Ryki on your behalf are not protected by any deposit
            insurance or investment protection scheme. You acknowledge
            that in no event will Ryki be responsible for any losses
            or damage to you as a result of these risks.
          </li>
        </ol>
      </dd>
      <dt>Representations and Warranties</dt>
      <dd>You represent and warrant that:
        <ol>
          <li>
            If you are an individual, that you are of the age of
            majority, that you have the capacity to enter into and be
            bound by these Terms and Conditions, and that you will use
            the Platform under your own name and for your sole
            benefit;
          </li>
          <li>
            If you are a legal entity other than an individual, the
            legal entity is duly organized and existing and the
            individual accepting these Terms and Conditions is of the
            age of majority and has the authority to enter into and
            cause the legal entity to be bound by these Terms and
            Conditions;
          </li>
          <li>
            The information provided by you upon to Ryki upon
            opening your Account is accurate and complete;
          </li>
          <li>
            You are not prohibited or restricted from using the
            Platform.
          </li>
        </ol>
      </dd>
      <dt>Liability disclaimer – READ THE FOLLOWING CAREFULLY</dt>
      <dd>The Platform is provided on an “as is” and “as available”
      basis. To the fullest extent permitted by law, Ryki expressly
      disclaims all representations and warranties, whether express
      or implied, statutory or otherwise, including:
        <ol>
          <li>any warranty of merchantability;</li>
          <li>fitness for a particular purpose;</li>
          <li>non-infringement;</li>
          <li>
            that the Platform or any Cryptocurrency acquired using
            the Platform will meet your requirements; and
          </li>
          <li>
            that your access to the Platform will be uninterrupted
            or error-free.
          </li>
        </ol>
      </dd>
      <dd>In no event will Ryki or any of its directors, officers,
      employees, or agents be liable for any injury, loss, claim,
      damage, or any special, exemplary, punitive, direct, indirect,
      incidental or consequential damages of any kind (including,
      but not limited to economic loss, foregone financial benefit
      or lost savings), whether based in contract, tort, strict
      liability, or otherwise, which arises out of or is in any way
      connected with any use of the Platform or its content, or the
      services offered on the Platform, even if advised of the
      possibility of damages.</dd>
      <dd>If Ryki should be found liable for any loss or damage which
      arises out of or is in any way connected with any of the
      functions or uses of the Platform or its content, or any of
      the services offered on the Platform, the liability of Ryki
      will in no event exceed in the aggregate the amount paid by
      you under these Terms and Conditions for the specific services
      to which the loss or damage relates.</dd>
      <dd>You are solely responsible for any and all tax consequences
      you may incur as a result of your use of the Platform.</dd>
      <dt>Indemnification</dt>
      <dd>You agree to indemnify and save harmless Ryki, its affiliates
      and any of their respective officers, directors, employees,
      consultants, representatives and agents from and against any
      claim, cause of action or demand, including without limitation
      reasonable legal, accounting and other professional fees,
      brought as a result of your use of the Platform or your breach
      of these Terms and Conditions.</dd>
      <dt>Survival</dt>
      <dd>Sections F, G and I will survive the termination of your
      access to the Platform.</dd>
      <dt>General</dt>
      <dd>These Terms and Conditions may not be assigned by you or
      otherwise transferred in whole or in part without the prior
      written consent of Ryki. Ryki may assign these Terms and
      Conditions without obtaining your consent.</dd>
      <dd>The relationship between Ryki and you will be that of
      independent contractors, and neither party nor any of its
      respective officers, agents or employees will be held or
      construed to be partners, joint ventures, fiduciaries,
      employees or agents of the other as a result of these Terms
      and Conditions or your use of the Platform.</dd>
      <dd>You consent and submit to the exclusive jurisdiction of the
      courts located in the Cayman Islands, in all disputes arising
      out of or relating to the use of the Platform and these Terms
      and Conditions. This agreement and its performance will be
      governed by the laws of the Cayman Islands.</dd>
      <dd>These Terms and Conditions will be severable. In the event
      that any provision is determined to be unenforceable or
      invalid, that provision will nonetheless be enforced to the
      fullest extent permitted by applicable law, and that
      determination will not affect the validity and enforceability
      of any other remaining provisions of these Terms and
      Conditions.</dd>
      <dd>These Terms and Conditions, together with those incorporated
      or referred to in these Terms and Conditions, constitute the
      entire agreement between you and Ryki pertaining to the
      subject matter of these Terms and Conditions, and supersede
      any prior agreements, understandings, negotiations and
      discussions, whether electronic, oral or written, regarding
      the subject matter of these Terms and Conditions, and may not
      be amended or modified except by the Company as set out above.
      There are no representations, warranties or other agreements
      between us in connection with the subject matter of these
      Terms and Conditions, except as specifically set out in these
      Terms and Conditions or in those incorporated or referred to
      in these Terms and Conditions. No party has been induced to
      enter into these Term and Conditions in reliance on, and there
      will be no liability assessed, either in tort or contract,
      with respect to, any warranty, representation, opinion, advice
      or assertion of fact, except to the extent it has been reduced
      to writing and included in these Terms and Conditions or in
      those incorporated or referred to in these Terms and
      Conditions.</dd>
      <dd>You consent to receive communications from Ryki
      electronically. You agree that all agreements, notices,
      disclosures and other communications that Ryki provides to you
      electronically satisfy any legal requirement that such
      communications be in writing.</dd>
      <dd>The legal business name of the Company is Ryki Ltd. Please
      direct enquiries to the Company’s registered business address
      at DDM Corporate Ltd., One Capital Place, 3rd floor, 136
      Shedden Road, PO Box 10190, George Town, Grand Cayman, Cayman
      Islands or by telephone at (226) 240-7189 or e-mail at
      admin@ryki.io.</dd>`,
    isActive: false,
  },
  {
    location: "british-virgin-islands",
    ourPartners: [
      {
        logo: AppleByLogo,
        alt: "Appleby Logo",
      },
      {
        logo: ProvenanceLogo,
        alt: "Provenance Logo",
      },
      {
        logo: DLTSolutionsLogo,
        alt: "DLT Solutions",
      },
    ],
    getInTouch: {
      phone: "(226) 240-7189",
      address:
        "Trinity Chambers, PO 4031 Road Town, Tortola, British Virgin Islands",
    },
    footerContent: `RYKI INC.<br />
    (226) 240-7189<br/>
    Trinity Chambers, PO 4031<br/>
    Road Town, Tortola, British Virgin Islands`,
    copyright: `© 2023. For information, contact Ryki Inc.`,
    kycAndAml: `<p>RYKI is committed to preventing fraud, money laundering and terrorist financing, as well as to complying with relevant legislation. As such, RYKI has applied to be a virtual asset service provider (VASP) with the British Virgin Islands Financial Services Commission (BVIFSC).</p>
    <br/>
    <p>Regulation requires RYKI to collect certain information about its clients and report certain transactions to law enforcement agencies as well as to develop a comprehensive compliance program that consists of:</p>
    <ul>
      <li>documented policies and procedures</li>
      <li>the designation of an individual responsible for the design and oversight of the compliance program</li>
      <li>an assessment of RYKI’s exposure to money laundering and terrorist financing risks</li>
      <li>the provision of ongoing training to members of our organization</li>
      <li>periodic reviews of the overall effectiveness of the program.</li>
    </ul>`,
    privacyPolicy: `<h3>Overview</h3>
    <p>
      Ryki Inc. (“ Ryki ”) is committed to helping protect the privacy and
      security of your personal information obtained by reason of your
      customer relationship with Ryki. Personal information is information
      about an identifiable individual, such as your name, address, telephone
      number, or email address (“ Personal Information ”). This privacy policy
      (“ Privacy Policy ”) summarizes what Personal Information Ryki collects,
      and how we use and disclose this information in providing you with
      trading services (the “ Services ”) and making Ryki.io available to you.
      This Privacy Policy also describes other important topics relating to
      your privacy. By using or accessing the Services, by visiting Ryki.io,
      or by otherwise choosing to submit Personal Information to Ryki, you
      signify your consent to all of the terms of this Privacy Policy. If you
      do not agree with any terms of this Privacy Policy, you may not use the
      Services or submit any Personal Information to Ryki. Ryki reserves the
      right to modify this Privacy Policy at any time. We will reflect any
      such modifications to this Privacy Policy on this pageand will indicate
      the date on which it was last amended. Your continued use of the
      Services or access to Ryki.io after any such changes constitutes your
      acceptance of, and agreement to, this Privacy Policy, as revised. Please
      periodically review this Privacy Policy so that you know what personal
      information we collect, how we use it, and with whom we may share it.
      This Privacy Policy covers:
    </p>
    <ol>
      <li>
        What Personal Information Ryki Collects.
        <ol>
          <li>
            a. We collect Personal Information when you register to use the
            Services, when we carry out the Services and when you give us
            information. When you open an account, we may collect your full
            name, contact information, proof of address (such as a hydro
            bill), birthdate, occupation, signature, and government ID (such
            as driver’s license, passport, or permanent resident card). If you
            represent a corporation, you will be asked to provide information
            about the company, and personal information of its beneficial
            owners and authorized signatories. You must have their permission
            to do so.
          </li>
          <li>
            When you use the Services, we collect contact information, the
            purpose, beneficiary, recipient and amount of a trade, the public
            key for your wallet, information on your bank account and
            institution needed for the transaction, and any other information
            you provide.
          </li>
          <li>
            When you visit the website, we collect browser information, the
            date and time of your visit, the pages you view, and the services
            you request.
          </li>
        </ol>
      </li>
      <li>
        How Ryki Uses Personal Information. We use information to provide our
        services, and to meet legal obligations. This includes ‘know your
        client’ and anti-money laundering obligations, protecting against
        fraud or errors, responding to questions, improving or developing
        services, communicating with you, and carrying out other purposes you
        agree to.
      </li>
      <li>
        How Ryki Discloses Personal Information. We disclose information to
        provide services you request (for example to an exchange to allow a
        trade), to our service providers, and if we enter a business
        transaction.
      </li>
      <li>
        Your Choices. You can opt-out of our commercial messages, if you
        receive them.
      </li>
      <li>
        How Ryki Protects Personal Information. While we use physical,
        organizational and technological safeguards, no security can provide
        absolute protection. We cannot ensure or warrant the security of any
        information.
      </li>
      <li>
        We may transfer Personal Information Outside of British Virgin Islands. We transfer
        information to the United States, and other countries. Information
        processed and stored in other countries may be accessed by governments
        and law enforcement according to their laws.
      </li>
      <li>
        Information Retention. We retain information as necessary to fulfill
        the purposes for which it was collected or to meet any legal
        requirements.
      </li>
      <li>
        Your right of Access and Rectification. You can request access to and
        correction of your personal information we hold.
      </li>
      <li>
        Third Party Sites. While this Site may link to third party sites, we
        are not responsible for them.
      </li>
      <li>
        How to Contact Us. You may contact us if you have questions or
        complaints at support@ryki.io.
      </li>
    </ol>`,
    termsAndCondition: `<p>PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. Your use
    of the services offered by Ryki Inc. and the content provided by
    Ryki on or through the services (collectively, the “ Platform ”)
    are expressly conditioned on your acceptance without modification
    of the following terms and conditions (the “ Terms and Conditions
    ”). That action is the equivalent of your signature and indicates
    your acceptance of these Terms and Conditions and that you intend
    to be legally bound by them. Ryki Inc. (“ Ryki ”) may modify,
    supplement or amend these Terms and Conditions at its sole
    discretion. If so, you will be notified the next time you use the
    Platform, and given an opportunity to accept the modifications.
    Your continued use of the Platform will be evidence that you
    acknowledge and agree to be bound by the modified Terms and
    Conditions. If you do not accept the modifications, you are no
    longer entitled to use the Platform.</p>
    <dl className="dd-number-list">
      <dt>Use of the Platform</dt>
      <dd>Ryki grants you a limited, non-exclusive, non-transferable and
      personal license to access and use the Platform, provided you
      have created an Account (as defined below) and are in compliance
      with these Terms and Conditions.</dd>
      <dd>All content included on the Platform is the property of Ryki or
      its content suppliers and protected by Canadian and
      international copyright laws. You agree not to permit access to
      the Platform to others, and not to publish, reproduce,
      sublicense or modify any content appearing on the Platform for
      any purpose without the prior written consent of Ryki.</dd>
      <dd>You may not use the Platform if you live in a jurisdiction where
      access to or use of the Platform or any part of it may be
      illegal or prohibited. It is solely your responsibility to
      determine whether your use of the Platform is lawful in the
      jurisdiction in which you reside, and you agree to comply with
      all applicable laws in your use of the Platform.</dd>
      <dd>You shall be responsible for all access and service fees
      necessary to connect to or communicate with the Platform and
      assume all charges incurred in accessing such systems.</dd>
      <dd>You will implement and maintain appropriate protection in
      relation to the security and control of access to your computer,
      computer viruses or other similar harmful or inappropriate
      materials, devices, information or data. You assume all risks
      associated with the use and storage of information on your
      personal computer or on any other computer through which you
      will gain access to the Platform.</dd>
      <dd>In its sole discretion and for any reason, in addition to any
      other rights or remediesavailable to Ryki and without any
      liability whatsoever, Ryki at any time and without notice may
      terminate or restrict your access to any component of the
      Platform.</dd>
      <dt>Platform Account</dt>
      <dd>You must create an account in order to access the Platform (your
        “ Account ”). When you create an Account, you will be required
        to provide personal information and about your use of the
        Platform to Ryki. Ryki may, in its discretion, accept or reject
        your application for an Account.</dd>
      <dd>All use, collection, and disclosure of your personal information
      will be governed by Ryki’s Privacy Policy, which is available at
      ryki.io/privacy. You have read Ryki’s Privacy Policy, and you
      agree that the terms of that policy are reasonable. You consent
      to the collection, use and disclosure of your personal
      information by Ryki in accordance with the terms of and for the
      purposes set out in Ryki’s Privacy Policy.</dd>
      <dd>You agree that Ryki, or a service provider engaged by Ryki, may
      verify your name, address, phone number, and other Account
      details against information about you held by third party
      sources in order to verify your identity to fulfill its
      anti-money laundering obligations and to help protect against
      fraud or misappropriation of user Accounts.</dd>
      <dd>You covenant and represent that you will provide truthful,
      accurate and complete information upon opening your Account. If
      any of the information provided by you to Ryki changes, you
      agree to provide Ryki with sufficient details no later than 30
      days after such change.</dd>
      <dd>Your Account may be accessed only by use of your account name
      and password, or if you are accessing the platform in person or
      by telephone, by other identifying information agreed upon
      between you and Ryki from time to time (your “ Credentials ”).
      You are solely responsible and liable for any use and misuse of
      your Credentials and for all activities that occur under your
      Credentials. For security reasons, you must keep your
      Credentials confidential and not disclose them to any person or
      permit any other person to use them, except with the prior
      written consent of Ryki.</dd>
      <dd>You must immediately notify Ryki of any unauthorized use of your
      Credentials, or if you know or suspect that your Credentials
      have been lost or stolen, has become known to any other person,
      or has been otherwise compromised. You agree to take full
      responsibility for all use and misuse that occurs under your
      Account (including all transactions using your Account) and for
      all resulting loss, damage, and liability.</dd>
      <dd>If you allow any third party to access the Platform (including
        any of your Accounts) in a manner unauthorized by Ryki
        (including, without limitation, screen scrapping), you will
        indemnify Ryki and its employees, officers, directors, and
        agents and hold each of them harmless against any and all
        losses, liability, costs or damages arising out of any claim or
        suit by any such third party based upon or relating in any way
        to such access or use of the Platform.</dd>
      <dt>Trading</dt>
      <dd>In order to initiate a trade using the Platform, you must first
      either: a. transfer United States Dollars or Canadian Dollars
      (collectively, “ Fiat Currency ”), to a bank account controlled
      by Ryki; or b. transfer bitcoin or ether (collectively, “
      Cryptocurrency ”) to a digital wallet controlled by Ryki.</dd>
      <dd>Fiat Currency or Cryptocurrency transferred by you to Ryki
      (hereinafter referred to as “ Customer Funds ”) in accordance
      with section 14 will be credited to your Account and held by
      Ryki on your behalf separately from its operating bank account.</dd>
      <dd>There will be no interest paid on any Customer Funds held by
      Ryki for your Account.</dd>
      <dd>Upon receiving written or verbal instructions from you, Ryki
      may faciliate a trade of your Customer Funds for another Fiat
      Currency or Cryptocurrency (a “ Trade ”). All instructions
      provided by you to Ryki are considered final and all Trades are
      non-refundable and executed on a reasonable efforts basis. Ryki
      is not responsible for any incorrect instructions provided by
      you.</dd>
      <dd>Any proceeds from a Trade by you will be credited to your
      Account, less any fees due to Ryki for the Trade and any other
      services Ryki may provide.</dd>
      <dd>Proceeds from each trade will be immediately transferred to your
      bank account via wire transfer or to your digital asset wallet
      on the blockchain. Transfers will occur as soon as commercially
      viable and not extending a period of 5 business days.</dd>
      <dd>Ryki’s prices are all in. Prices take into account all expenses
      incurred by Ryki and are considered binding for both parties.</dd>
      <dt>Risks and Prohibited Conduct</dt>
      <dd>You acknowledge that Ryki does not guarantee the accuracy of
      exchange rates and does not guarantee that the Trades
      facilitated by Ryki will be favourable or be based on accurate
      information. You are solely responsible for any trading decision
      made and accept any loss or foregone financial benefit, even if
      the decision is based on information provided by Ryki.</dd>
      <dd>Ryki does not provide any trading advice or recommendations. No
      orders are reviewed by Ryki for suitability.</dd>
      <dd>You are responsible for complying with applicable laws in your
      use of the Platform. Do not use the Platform if buying and
      selling Cryptocurrency is not legal in your jurisdiction.</dd>
      <dd>You agree that Ryki may, in its discretion, determine not to
      hold digital assets that result from hard forks of
      Cryptocurrencies. In this circumstance, if you wish to acquire
      assets resulting from a hard fork, you should withdraw your
      Customer Funds prior to the hard fork.</dd>
      <dd>You acknowledge that Cryptocurrencies involve significant risk.
      These risks include, but are not limited to:
        <ol>
          <li>Cryptocurrency prices are volatile;</li>
          <li>digital wallets and exchanges may be hacked;</li>
          <li>
            holders of Cryptocurrencies do not have the same legal
            rights associated with security ownership or deposits with
            regulated financial institutions;
          </li>
          <li>
            it may be difficult to find accurate and current
            information relating to Cryptocurrencies;
          </li>
          <li>e. Trades may take several days to settle; and</li>
          <li>
            amounts of Fiat Currency and Cryptocurrency held by Ryki
            on your behalf are not protected by any deposit insurance or
            investment protection scheme. You acknowledge that in no
            event will Ryki be responsible for any losses or damage to
            you as a result of these risks.
          </li>
        </ol>
      </dd>
      <dd>Representations and Warranties</dd>
      <dd>You represent and warrant that:
        <ol>
          <li>
            If you are an individual, that you are of the age of
            majority, that you have the capacity to enter into and be
            bound by these Terms and Conditions, and that you will use
            the Platform under your own name and for your sole benefit;
          </li>
          <li>
            If you are a legal entity other than an individual, the
            legal entity is duly organized and existing and the
            individual accepting these Terms and Conditions is of the
            age of majority and has the authority to enter into and
            cause the legal entity to be bound by these Terms and
            Conditions;
          </li>
          <li>
            The information provided by you upon to Ryki upon opening
            your Account is accurate and complete;
          </li>
          <li>
            You are not prohibited or restricted from using the
            Platform.
          </li>
        </ol>
      </dd>
      <dt>Liability disclaimer – READ THE FOLLOWING CAREFULLY</dt>
      <dd>The Platform is provided on an “as is” and “as available” basis.
      To the fullest extent permitted by law, Ryki expressly disclaims
      all representations and warranties, whether express or implied,
      statutory or otherwise, including:
        <ol>
          <li>any warranty of merchantability;</li>
          <li>fitness for a particular purpose;</li>
          <li>non-infringement;</li>
          <li>
            that the Platform or any Cryptocurrency acquired using
            the Platform will meet your requirements; and
          </li>
          <li>
            that your access to the Platform will be uninterrupted
            or error-free.
          </li>
        </ol>
      </dd>
      <dd>In no event will Ryki or any of its directors, officers,
      employees, or agents be liable for any injury, loss, claim,
      damage, or any special, exemplary, punitive, direct, indirect,
      incidental or consequential damages of any kind (including, but
      not limited to economic loss, foregone financial benefit or lost
      savings), whether based in contract, tort, strict liability, or
      otherwise, which arises out of or is in any way connected with
      any use of the Platform or its content, or the services offered
      on the Platform, even if advised of the possibility of damages.</dd>
      <dd>If Ryki should be found liable for any loss or damage which
      arises out of or is in any way connected with any of the
      functions or uses of the Platform or its content, or any of the
      services offered on the Platform, the liability of Ryki will in
      no event exceed in the aggregate the amount paid by you under
      these Terms and Conditions for the specific services to which
      the loss or damage relates.</dd>
      <dd>You are solely responsible for any and all tax consequences you
      may incur as a result of your use of the Platform.</dd>
      <dt>Indemnification</dt>
      <dd>You agree to indemnify and save harmless Ryki, its affiliates
      and any of their respective officers, directors, employees,
      consultants, representatives and agents from and against any
      claim, cause of action or demand, including without limitation
      reasonable legal, accounting and other professional fees,
      brought as a result of your use of the Platform or your breach
      of these Terms and Conditions.</dd>
      <dt>Survival</dt>
      <dd>Sections F, G and I will survive the termination of your access
      to the Platform.</dd>
      <dt>General</dt>
      <dd>These Terms and Conditions may not be assigned by you or
      otherwise transferred in whole or in part without the prior
      written consent of Ryki. Ryki may assign these Terms and
      Conditions without obtaining your consent.</dd>
      <dd>The relationship between Ryki and you will be that of
      independent contractors, and neither party nor any of its
      respective officers, agents or employees will be held or
      construed to be partners, joint ventures, fiduciaries, employees
      or agents of the other as a result of these Terms and Conditions
      or your use of the Platform.</dd>
      <dd>You consent and submit to the exclusive jurisdiction of the
      courts located in the British Virgin Islands, in all disputes
      arising out of or relating to the use of the Platform and these
      Terms and Conditions. This agreement and its performance will be
      governed by the laws of the British Virgin Islands.</dd>
      <dd>These Terms and Conditions will be severable. In the event that
      any provision is determined to be unenforceable or invalid, that
      provision will nonetheless be enforced to the fullest extent
      permitted by applicable law, and that determination will not
      affect the validity and enforceability of any other remaining
      provisions of these Terms and Conditions.</dd>
      <dd>These Terms and Conditions, together with those incorporated or
      referred to in these Terms and Conditions, constitute the entire
      agreement between you and Ryki pertaining to the subject matter
      of these Terms and Conditions, and supersede any prior
      agreements, understandings, negotiations and discussions,
      whether electronic, oral or written, regarding the subject
      matter of these Terms and Conditions, and may not be amended or
      modified except by the Company as set out above. There are no
      representations, warranties or other agreements between us in
      connection with the subject matter of these Terms and
      Conditions, except as specifically set out in these Terms and
      Conditions or in those incorporated or referred to in these
      Terms and Conditions. No party has been induced to enter into
      these Term and Conditions in reliance on, and there will be no
      liability assessed, either in tort or contract, with respect to,
      any warranty, representation, opinion, advice or assertion of
      fact, except to the extent it has been reduced to writing and
      included in these Terms and Conditions or in those incorporated
      or referred to in these Terms and Conditions.</dd>
      <dd>You consent to receive communications from Ryki electronically.
      You agree that all agreements, notices, disclosures and other
      communications that Ryki provides to you electronically satisfy
      any legal requirement that such communications be in writing.</dd>
      <dd>The legal business name of the Company is Ryki Inc. Please
      direct enquiries to the Company’s registered business address at
      Trinity Chambers, PO Box 4301, Road Town, Tortola, British
      Virgin Islands or by telephone at (226) 240-7189 or e-mail at
      admin@ryki.io.</dd>
    </dl>`,
    isActive: false,
  },
];
