import { useSelector } from "react-redux";
import "./style.css";
import { RootState } from "../../redux/store";
import parse from 'html-react-parser';;

const KycAmlPolicy = () => {
  const currentLocation = useSelector((state: RootState) => state.location.currentLocation);
  
  return (
    <div className="kyc-aml-policy-block">
      <h2 className="kyc-aml-policy-block__header">KYC & AML Policy</h2>
      {parse(currentLocation.kycAndAml)}
    </div>
  );
};

export default KycAmlPolicy;
