import { useSelector } from "react-redux";
import "./style.css";
import { RootState } from "../../redux/store"
import parse from 'html-react-parser';;

const TermsOfUse = () => {
  const currentLocation = useSelector((state: RootState) => state.location.currentLocation);
  
  return (
    <div className="terms-of-use-block">
      <h2 className="terms-of-use-block__header">Terms of use</h2>
      {parse(currentLocation.termsAndCondition)}
    </div>
  );
};

export default TermsOfUse;
