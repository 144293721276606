import { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { close } from "../../redux/features/modalSlice";
import "./style.css";

const Modal = ({
  children,
  classes,
}: {
  children: ReactNode;
  classes?: string;
}) => {
  const dispatch = useDispatch();
  const modalControlRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const [hasFade, setHasFade] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const [isMaxScroll, setIsMaxScroll] = useState(false);

  useEffect(() => {
    document.body.classList.add('modal-active');
    const modalControlElem = modalControlRef.current;
    const modalInnerElem = modalInnerRef.current;

    if (modalControlElem && modalInnerElem) {
      const checkScroll = (a: Event) => {
        const targetElem = a.target as Element;
        const currentScrollTop = targetElem.scrollTop;
        const limitScrollHeight = targetElem.scrollHeight;
        
        if (
          Math.ceil(currentScrollTop + (targetElem as HTMLDivElement).offsetHeight) >=
          limitScrollHeight
        ) {
          setIsMaxScroll(true);
        } else {
          setIsMaxScroll(false);
        }
      };

      const modalControlElemHeight = modalControlElem.clientHeight;
      const modalInnerElemHeight = modalInnerElem.clientHeight;
      if (modalInnerElemHeight > modalControlElemHeight) {
        setHasFade(true);
        setHasScroll(true);
      } else {
        setHasFade(false);
        setHasScroll(false);
      }

      modalControlElem.addEventListener("scroll", checkScroll);

      return () => {
        document.body.classList.remove('modal-active');
        modalControlElem.removeEventListener("scroll", checkScroll);
      };
    }
  }, []);

  const classList = [
    "modal-block",
    classes ? classes : "",
    hasFade ? "modal-block--has-fade" : "",
    hasScroll ? "modal-block--has-scroll" : "",
    isMaxScroll ? "modal-block--max-scroll" : "",
  ];

  return (
    <div className={classList.join(" ")}>
      <div className="modal-block__overlay" onClick={() => dispatch(close())}></div>
      <div className="modal-block__inner">
        <div className="modal-block__popup">
          <div className="modal-block__popup-header">
            <button
              className="modal-block__popup-header-close-btn"
              aria-label="Close Modal"
              onClick={() => dispatch(close())}
            >
              <span className="icon icon-close"></span>
            </button>
          </div>
          <div className="modal-block__popup-content">
            <div className="modal-block__popup-content-outer">
              <div
                className="modal-block__popup-content-control"
                ref={modalControlRef}
              >
                <div
                  className="modal-block__popup-content-inner"
                  ref={modalInnerRef}
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
