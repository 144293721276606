import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import "./style.css";

const Header = () => (
  <header className="header-block">
    <div className="container">
      <NavLink to="/" aria-label="Home Link" className="header-block__logo">
        <Logo />
      </NavLink>
    </div>
  </header>
);

export default Header;
