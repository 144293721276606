import "./style.css";

const TradeAndInvest = () => (
  <section className="trade-and-invest-block">
    <div className="container">
      <div className="trade-and-invest-block__intro scroll-animate scroll-animate__left-origin">
        <div className="trade-and-invest-block__large-text">
          A white glove service to securely
        </div>
        <h2 className="trade-and-invest-block__heading">
          trade and invest in digital assets
        </h2>
        <p className="trade-and-invest-block__description">
          RYKI is a digital asset gateway offering access to the worlds best liquidity. RYKI has access to extensive market resources that are fine tuned to your specific needs. By taking a strategic approach, RYKI will craft a solution to suit your specific goals.
        </p>
      </div>
    </div>
  </section>
);

export default TradeAndInvest;
