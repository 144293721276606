import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LocationDataType } from "../../components/types/getInTouch";
import { locationData } from "../../components/FindUs/data";

const initialState: { currentLocation: LocationDataType } = {
  currentLocation: {...locationData[0]},
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setCurrentLocation: (state, action: PayloadAction<LocationDataType>) => {
      state.currentLocation = action.payload;
    },
  },
});

export const { setCurrentLocation } = locationSlice.actions;

export default locationSlice.reducer;
